<template>
   <section class="section-news">
      <div class="container">
         <div class="section-news__row">
            <div class="section-news__title title">Новости </div>
            <slot><more-btn to="news" /></slot>
         </div>
         <div class="section-news__grid">
            <router-link :to="'/article/' + news.main.slug" class="section-news__main main-article"
               :class="{ skeleton: !loadImages }">
               <div class="main-article__image">
                  <img class="" :src="news.main.get_image" alt="" :onload="onLoadImage">
               </div>
               <div class="main-article__content">
                  <div class="main-article__date">{{ CorDate(news.main.date_added) }}</div>
                  <div class="main-article__title">{{ news.main.title }}</div>
                  <p class="main-article__subtitle">{{ news.main.subtitle }}</p>
               </div>
            </router-link>
            <template v-for="item in 3" :key="item">
               <div class="skeleton secondary-article__image" v-if="!loadImages">

               </div>
            </template>
            <router-link v-for="(article, index) in news.secondary" :key="index" :to="'/article/' + article.slug"
               class="section-news__small secondary-article">
               <div class="secondary-article__row">
                  <div class="secondary-article__image art_max-width" :class="{ skeleton: !loadImages }">
                     <img :src="article.get_image" alt="" :onload="onLoadImage">
                  </div>
                  <div class="secondary-article__content">
                     <div class="secondary-article__date">{{ CorDate(article.date_added) }}</div>
                     <div class="secondary-article__title">{{ article.title }}</div>
                     <div class="secondary-article__desc" v-html="article.small_disc">
                     </div>
                  </div>
               </div>
            </router-link>
         </div>
      </div>
   </section>
</template>
<script>
import moreBtn from "../Buttons/more-btn"
import moment from 'moment';
// import vLoading from "@/components/Loader/v-loader"
export default {
   name: "news-section",
   components: {
      moreBtn,
      // vLoading
   },
   props: {
      news: {
         type: Object,
         default() {
            return {}
         }
      }
   },
   data() {
      return {
         loading: false,
         countLoad: 0,
         loadImages: false,
      }
   },
   methods: {
      CorDate(date) {
         return moment(String(date), "YYYY-MM-DD").format('DD MMMM YYYY')
      },
      onLoadImage() {
         this.countLoad++
         if (this.news.secondary.length + 1 == this.countLoad) {
            this.loadImages = true
         }
      }
   },
   mounted() {
      setTimeout(() => {
         this.loading = false
      }, 300)
   },
}
</script>
<style lang="scss">
.art_max-width {
   max-width: 240px;
}

// THE LOADING EFFECT
.skeleton {
   position: relative;

   &.secondary-article__image {
      min-height: 150px;
      width: 100%;
   }

   &.section-news__main {
      height: 636px;
      @media(max-width: 992px) {
         min-height: 250px;
         height: auto;
      }
      @media(max-width: 576px) {
         min-height: 150px;
         height: auto !important;
      }
   }

   &::after {
      content: "";
      display: block;
      position: absolute;
      inset: 0;
      z-index: 1;
      background-color: #e2e5e7;
      // The shine that's going to move across the skeleton:
      background-image:
         linear-gradient(90deg,
            rgba(#fff, 0),
            rgba(#fff, 0.5),
            rgba(#fff, 0));
      background-size: 40px 100%; // width of the shine
      background-repeat: no-repeat; // No need to repeat the shine effect
      background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
      animation: shine 1s ease infinite; // increase animation time to see effect in 'slow-mo'
   }

}

@keyframes shine {
   to {
      // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
      background-position: right -40px top 0;
   }
}

.section-news__main {
   overflow: hidden;
}
</style>